import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import dateFormat from 'dateformat';
import moment from 'moment-timezone';

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

const displayDate= (dates) => {
    if(dates && dates[0]) {
    let to = dates[0].endDateAndTime != undefined ? "-" : "";
    let start_date = moment(dates[0].date).tz("Europe/Berlin").format("D.MM.YY");
    let end_date = moment(dates[0].endDate).tz("Europe/Berlin").format("D.MM.YY");
    let start = <div>{start_date} {to}</div>
    let end = dates[0].endDateAndTime != undefined ? <div>{end_date}</div> : "";
  return [start,end];
  }
  return ["",""];
}

const Dates = ({ dates, children }) => (
  <div>{displayDate(dates)}</div>
)


export default Dates
