import React from "react"
import { useStaticQuery, graphql,Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import "../css/custom.css"
import styled from "styled-components"


var ImageOuterContainer = styled.div`
  margin: 10px auto;
  width: 100%;
  position: relative;
  grid-column-end: span ${props => props.span ? props.span : 3};;
  align-self: stretch;

  padding: 2.5vh;
  transition: all 0.5s;
  cursor: pointer;
  
  @media (min-width: 600px) {
    height:auto;
    margin: 0px auto;  
  }
`

var ImageInnerContainer = styled.div`


`

var ImageDetails = styled.div`

  position:relative;

  text-align:center;
  margin-top:20px;
  
  @media (min-width: 600px) {
    background-color: rgba(255,255,255,0.9);
    padding:40px 40px 80px 40px;
    overflow:hidden;
    margin-top:0;
    box-sizing: border-box;
    opacity:1;
    text-align:left;
    transition:1.5s all ease;
    left:0;right:0;top:0;bottom:0;
    position:absolute;
    opacity:0;
    &:hover {
      opacity:1;
    }
  }

.dimensions {
  position:absolute;
  bottom:40px;
}
.title {
  display:inline;
  padding-bottom:5px;
    padding-top:140px;
    @media (min-width: 600px) {
    margin-bottom:30px;
    }
  
}
.materials {
  @media (min-width: 600px) {
    padding-top:7px;
  }
  font-style:italic;
}
.dimensions {
  display:none;
    @media (min-width: 600px) {
      display:block;
          line-height: 28px;
    }
  white-space: pre-wrap;  
}
.dimensions span {
  display:block;
  font-size:20px;
}`

const imageRender = ({image,total_columns, span}) => {

  let details = image.details?  image.details : undefined;
  let url = image.url;
  if(image.type == "stacked") {

return <div

    style={{
      margin: `0 auto`,
      width: "100%",
      gridColumnEnd: `span ${span}` ,
       "alignSelf": "stretch",
        "height": `calc((70vh / ${total_columns - 0.15 * total_columns * total_columns}))`,
      transition: "all 0.5s",
      cursor: "pointer", 
  
    }}
  ><div style={{width: "100%", 
          height: "80%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position:"relative",
          backgroundPosition: "center center",
                gridColumnEnd: `span ${span}` ,  
          backgroundImage: `url(${url})`}}>
      
      
        </div><div style={{
          textAlign:"center",
          padding:"20px"
      
        }}>{details}</div></div>
        
  }
  else if(image.type != "text" && image.size != "full") {

    
return <ImageOuterContainer
    className="object"
  ><div style={{width: "100%", 
          height: "400px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position:"relative",
          backgroundPosition: "center center",
                gridColumnEnd: `span ${span}` ,  
          backgroundImage: `url(${url})`}}>
      
        
        </div><ImageDetails>{details}</ImageDetails></ImageOuterContainer>
        
  }

  else if (image.type != "text" && image.size == "full") {
    return <div
        
        style={{
          margin: `0 auto`,
          width: "100%",
          height:"90vh",
         "alignSelf": "start",
          padding: "2.5vh",
          transition: "all 0.5s",
          cursor: "pointer",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundImage:  `url(${url})`,
              gridColumnEnd: `span ${span}` ,
        }}
      ></div>
            
      
  }
  if(image.type == "text") {
    
    let backgroundColor = image.subtype == "highlight" ? "rgba(60, 230, 200,1)": "transparent";
    
    return <div
        className="text-i"
        style={{
              gridColumnEnd: `span ${span}` ,
          margin: `0 auto`,
          width: "100%",
      
          backgroundColor,
          position:"relative",
          color:"black",
          padding: image.subtype == "highlight" ? "2.5vh": 0,
          fontSize: image.subtype == "highlight" ? "40px": "16px",
          transition: "all 0.5s",
          cursor: "pointer",
      
        }}
      ><div>
    {image.text}
    </div></div>
  }
}

const Image = ({image,total_columns}) =>  {
  let span = image.span? image.span : 12 / total_columns;
  return image.link ? <Link to={image.link} style={{gridColumnEnd: `span ${span}`}}>{imageRender({image,total_columns,span})}</Link> : imageRender({image,total_columns,span})
}
Image.propTypes = {
  image: PropTypes.object
}

Image.defaultProps = {
  images: {},
}


export default Image


