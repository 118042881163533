/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

function Titles({titles}) {
  let h1 = titles.h1 ? <h1>{titles.h1}</h1> : "";
  let h2 = titles.h2 ? <h2>{titles.h2}</h2> : "";
  let h3 = titles.h3 ? <h3>{titles.h3}</h3> : "";
  return (
  <div className="titles inline">
    {h1}{h2}{h3}
  </div>
  )
}

Titles.defaultProps = {

}

Titles.propTypes = {

}

export default Titles
