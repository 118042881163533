import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'

import Layout from "../components/layout"

import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import Dates from "../components/dates"
import SEO from "../components/seo"
import Carousel from "../components/carousel"

let gql_query = graphql`{
    exhibitions: craftAPI {
      entries(type: "exhibition") {
    ... on CraftAPI_exhibition_exhibition_Entry {
      id
      locationUrl
      location
      title
      slug
      pageText
      date 
      endDate
      images {
        ... on CraftAPI_artworks_Asset {
          id
          url
          filename
          slug
          img
        }
      }
      artist {
          ... on CraftAPI_artist_artist_Entry {
        id
        slug
        title
        images {
          ... on CraftAPI_artworks_Asset {
            id
            url
            filename
            slug
            img
          }
        }
      }
            }
    
    artworks {
      ... on CraftAPI_artwork_artwork_Entry {
        id
        slug
        title
        artist {
          ... on CraftAPI_artist_artist_Entry {
            id
            slug
            title
          }
        }
        images {
          ... on CraftAPI_artworks_Asset {
            id
            url
            filename
            slug
            img
          }
        }
      }
    }
            }
  }
}
}
`

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const artists = [
  {type:"stacked", link:"/artist-2", url:"/michael-1.jpg", details:<div><div className="title">Michael Vaynman</div></div>},
  {type:"stacked", link:"/artist-2", url:"/adam-gottlieb-1.jpg", details:<div><div className="title">Adam Gottlieb</div></div>},
  {type:"stacked", link:"/artist-2", url:"/TREBILCOCK_lorikeets.jpg", details:<div><div className="title">Stephen Trebilcock</div></div>},
]
const artists2 = [
  {type:"stacked", link:"/artist-2", url:"/isabel-1.jpg", details:<div><div className="title">Isabel Devos</div></div>},
  {type:"stacked", link:"/artist-2", url:"/angela-goh-2.jpg", details:<div><div className="title">Angela Goh</div></div>},
  {type:"stacked", link:"/artist-2", url:"/Australavians_Sally_Hook.jpg", details:<div><div className="title">Sally Hook</div></div>},
]
const artists3 = [
  {type:"stacked", url:"/leo.jpg", details:<div><div className="title">Michael Vaynman</div></div>},
  {type:"stacked", url:"/jane-1.jpg", details:<div><div className="title">Adam Gottlieb</div></div>},
  {type:"stacked", url:"/images/sue-01.jpg", details:<div><div className="title">Susan Trebilcock</div></div>},
]
const titles = {"h2":"Featured Artist","h3":"Michael Vaynman"}


const artist_rows = (artists) => {

  let artist_rows = artists.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"stacked", 
    link:"/" + item.slug,
    url:item.images?.[0]?.url,
    details:<div><div className="title">{item.title}<br />20th May 2021 - 24th June 2021</div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow images={row} /> )
  
  
  
  
  return artist_rows
}
const hero = <Carousel  />
const Artists = () => (
        <StaticQuery query={gql_query} render={(data) => {
            
          
  return <Layout hero={undefined} intro={false}>
    <SEO title="Home" />
    <div style={{ }}>
      
  <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Exhibitions</h1>
      {artist_rows(data.exhibitions.entries)}


          

    </div>
  </Layout>}} />
)

export default Artists
